:root {
    --main-color: #0a2f56;
    --main-color-disabled: #1c5fa7;
}

body {
    background-color: #F1F2F4 !important;
}

div.MuiDrawer-root.Muidrawer-Modal {
    background-color: #fff;
}

div.MuiDrawer-root.Muidrawer-Modal[role=presentation] {
    background-color: #fff;
}

hr.MuiDivider-root {
    background-color: rgba(0, 0, 0, 0);
}

/*dashboard*/

.chartjs-render-monitor {
    display: block;
    height: 400px;
    width: 414px;
}

.card-body.bar {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card.right {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

/*text*/
.text-certificates-dash-box {
    color: #000000 !important;
}

/*progress bar*/
.bg-primary {
    background-color: #0e173a !important;
    color: #fff !important;
}

.bg-second {
    background-color: #0e173a !important;
    color: #fff !important;
}

.w-50 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

/*for the fields with borders  */
.box-container {
    margin-top: 1rem;
}

/*navbar*/
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.mui-fixed.MuiPaper-elevation4 {
    box-shadow: none;
}

div.MuiPaper-root.MuiDrawer-paper {
    top: 0;
    width: 14em;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 0;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    margin: 80px 0px 0px 10px;
}

.MuiDrawer-paperAnchorDockedLeft {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-colorInherit {
    margin-left: auto;
}

@media screen and (max-width: 450px) {
    div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
        margin: 0px;
    }
}

@media screen and (max-width: 599px) {
    div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
        margin: 0px;
    }
}

/*main container on all pages*/

main.MuiBox-root.MuiBox-root-17 {
    padding: 0px;
}

.css-cnd76u-MuiPaper-root {
    background-color: #fff;
    height: 100%;
    margin: 27px 0px 0px 29px;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}

div.MuiContainer-root.MuiContainer-maxWidthLg {
    background-color: #fff;
    width: 8em;
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 0;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    margin: 90px 0px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 0px;
}

.content-page {
    margin-left: 220px;
    /* border-left: 7px solid #f5f5f5; */
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin-top: 80px;
    margin-right: 25px;
}

.content-page .content {
    padding: 10px 15px 80px 15px;
    min-height: 1040px;
    position: relative;
}

.content-page .content .container-fluid h2 {
    margin-bottom: 2rem;
}

.create-new-button button {
    margin-bottom: 1rem;
}

/* input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    color: #fff;
    position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
    position: absolute !important;
    border-left: 1px solid #ced4da;
    padding: 2px 5px;
    color: #000;
    left: 58px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
    position: absolute !important;
    border-left: 1px solid #ced4da;
    padding: 2px 5px;
    color: #000;
    left: 26px;
}


input[type="date"]::-webkit-datetime-edit-day-field {
    position: absolute !important;
    color: #000;
    padding: 2px 0px;
    left: 0px;
} */

@media screen and (max-width: 450px) {
    .content-page {
        margin-left: 25px;
    }
}

@media screen and (max-width: 599px) {
    .content-page {
        margin-left: 25px;
    }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
}

.buttonTemplates {
    display: flex;
    justify-content: flex-end;
}

.table.table-bordered.table-bordered.dt-responsive.nowrap.dataTable.no-footer.dtr-inline.collapsed {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
}

.sorting_asc {
    width: 127px;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

/*login page*/
.Login {
    padding: 60px 0;
}

.login-main {
    margin: 0 auto;
    max-width: 320px;
}

.login-button {
    float: right;
    margin-top: 20px;
}

.container-login {
    height: 100vh;
}

.loginLogo {
    text-align: center;
}

/* .alert {
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 60%;
    left: 20%;
  } */

.login-alert {
    margin: 0 auto;
    width: 90%;
    max-width: 600px;
    margin-bottom: 1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

/*end login*/

/* logout */
.topbarRightSide {
    flex: auto;
}

.nameLogout {
    display: flex;
    justify-content: end;
    align-items: center;
}

.displayName {
    padding-right: 10px;
    font-size: 1rem;
}

.logoutButton.btn.btn-primary {
    background-color: #0D173A;
}

/*form for certificate*/
.tableButton {
    padding: 0 10px 0 0;
}

.tableButton svg {
    height: 35px;
    width: 35px;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
}

.tableButton svg:hover {
    box-shadow: inset 0px 0px 6px 2px #bac6f1;
    transform: scale(1.1);
}

.tableButton svg.delete {
    color: #b31b1b;
}

.tableButton svg.calendar {
    color: #34a2c7;
}

.tableIcon {
    width: 35px;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
}

.tableIcon:hover {
    box-shadow: inset 0px 0px 6px 2px #bac6f1;
    transform: scale(1.1);
}

.notSavedIcon {
    box-shadow: inset 0px 0px 6px 2px #ff4040;
}

.form-group {
    margin: 0.8em;
}

.input-group {
    justify-content: space-between;
}

.btn-loadingSpinner.btn-loadingSpinner-primary {
    margin: 2.2rem;
    color: #ffff;
    background-color: #212529;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.activateLoading {
    position: fixed;
    background-color: rgba(0, 0, 0, .25);
    border-radius: 25%;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
}

.previewCard {
    height: 540px;
}

.previewCard iframe {
    width: 1296px;
    scale: calc(0.6);
    transform: translateX(-210px) translateY(-220px);
}

.previewLetter {
    height: 720px;
    box-shadow: 1px 1px 4px 1px black;
}

.btn-primary {
    float: right;
    /*hmm, interesting*/
    color: #fff;
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--main-color-disabled);
    border-color: var(--main-color-disabled);
}

.btn-primary img {
    padding-right: 10px;
}

/*table column color*/
.sc-dlfnuX.elQTsw.rdt_TableHeadRow {
    background-color: #f0f0f0;
}

/*modal*/
.modal-content {
    margin-top: 10rem;
}

/* styling for inside the listing info on modal row */
.rightbox {
    float: right;
}

.leftbox {
    float: left;
}

.loginLogo img {
    vertical-align: middle;
    border-style: none;
    max-width: 200px;
    height: auto;
}

.btn-close::before {
    content: 'X';
    font-size: 16px;
    font-family: Verdana;
}

.btn-close {
    background: none;
    border: none;
}

.fileupload-path {
    display: none;
}

.form-check-input {
    width: 16px;
    height: 16px;
}

.form-check-label {
    margin-top: 5px;
}